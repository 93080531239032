import Hero from "../components/Hero";
import ClubSection from "../components/ClubSection";
import Footer from "../components/Footer";

    

const HomePage = (props) => {

    const cardIdCollector = (id) => {
        props.HomePageDataCollector(id)
    }

    const mainModalStateHandler = (data) => {
        props.mainModalStateCollector(data)
    }



    return <>
        <Hero/>
        <ClubSection cardIdCollector={cardIdCollector} mainModalStateHandler = {mainModalStateHandler}/>
        
    </>
}

export default HomePage;