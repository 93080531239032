import classes from "./AboutUs.module.css"


const Aboutus = () => {
    return <div className={classes.AboutUs_MainParent}>
        <div className={classes.ContentSection}>
            <h2>About Us</h2>
            <p>
                Unravelsciences has been created with a vision to help high school students explore their passions and deep-seated interests. We facilitate our objective through multiple student clubs, research work, curated events etc. <br/><br/>

                By joining various clubs, students get an opportunity to work with like-minded communities and gain from each other's unique experiences. The different contests, events, workshops and seminars allow participants to expand their knowledge horizons and sharpen their skills. <br/><br/>

                Join today and be part of this exciting and fulfilling journey!  </p>
        </div>
        <div className={classes.AnimationSection}>
            
            
            

        </div>

    </div>
}

export default Aboutus