import classes from "./Footer.module.css"
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { BiPhoneCall } from "react-icons/bi";
import logoImg from "../Assets/logo.png"

const Footer = () => {
    return <>
        <div className={classes.Footer}>
            
                <div><img width="120px" src={logoImg}/></div>
                <div className={classes.address}>Address: <br/> Alethia Education <br/> Services Private Limited<br/>A-8, Lower Ground Floor,<br/> Rajouri Garden,Delhi-110027 </div>
                <div className={classes.gmail}>Contact Us At:<br/> <a href="mailto:unravel@gmail.com">unravelsciences@gmail.com<br/><BiPhoneCall color="black"/><a href="tel:9811293444">9811293444</a> </a></div>
                <div className={classes.SocialIcons}>
                   <a href="https://www.facebook.com/profile.php?id=100093684490416"><FaFacebookSquare/></a>
                    <a href="https://www.instagram.com/unravelsciences/"><FaInstagram/></a>
                    <a href="https://in.linkedin.com/in/unravel-sciences-54652527b"><FaLinkedin/></a>
                    <a href="https://twitter.com/UnravelSciences"><FaTwitterSquare/></a>
                    
                </div>
            
            
        </div>
       
    </>
}

export default Footer;