import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import ClubSection from './components/ClubSection';
import Footer from './components/Footer';
import Modal from './components/Modal';
import { useState, useEffect } from 'react';
import Navmenu from './components/Navmenu';
import ScreenLockImg from "./Assets/screenLock.svg"
import Aboutus from './components/AboutUs';
import HomePage from './Pages/Homepage';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Team from './components/Team';
import Events from './components/Events';

function App() {

  const [mainModalState, setMainModalState] = useState(false)
  const [cardId, setCardId] = useState(0)
  const [hamburgerState, setHamburgerState] = useState(false)

  const mainModalStateHandler = (data) => {
      setMainModalState(data)
  }

  const HomePageDataCollector = (id) => {
      setCardId(id)
  }
  

  const hamburgerDataCollector = (data) => {
    setHamburgerState(data)
  }

  const navMenuClickCollector = (data) => {
    setHamburgerState(false)
  }

  return (
    <Router>
    <div className="App">
          <div className='Screen-Orientation-Locker'>
              <img width="100px" src={ScreenLockImg}/>
              <h2>Rotate The Screen To Continue</h2>
          </div>

          {mainModalState ? <Modal cardId={cardId} mainModalStateHandler = {mainModalStateHandler}/> : ""}
          <Navbar navMenuClickCollector={navMenuClickCollector} hamburgerState={hamburgerState} hamburgerDataCollector={hamburgerDataCollector}/>
          <Navmenu navMenuClickCollector={navMenuClickCollector} navMenuState={hamburgerState}/>
          
         
            <Routes>
              <Route path="/" element={<HomePage HomePageDataCollector={HomePageDataCollector} mainModalStateCollector={mainModalStateHandler}/>} />
              <Route path="/about_us" element={<Aboutus/>}/>
              <Route path='/team' element={<Team/>}/>
              <Route path='/events' element={<Events/>}/>
            </Routes>
          

          {/* HomePage */}
          {/* <HomePage HomePageDataCollector={HomePageDataCollector}/> */}

          {/* AboutUs Page */}
          {/* <Aboutus/> */}


          <Footer/>
        
    </div>
    </Router>
  );
}

export default App;
