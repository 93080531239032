import classes from "./ProfileModal.module.css"
import { BiX } from "react-icons/bi";


const ProfileData = [
    {
        title: "Alok Bansal",
        data1: "Alok Bansal is a Post Graduate in Management from Xavier Institute of Management, Bhubaneswar and an Engineering graduate from Delhi Technological University (Formerly known as Delhi College of Engineering). Besides, he has done a Course in Leadership from Harvard Business School.",
        data2: "He has worked in senior management positions at companies like ICICI Lombard, Larsen & Toubro, IBP Caltex, ExxonMobil etc.",
        data3: "An Expert Career Counsellor & Mentor, he appears in a lot of TV shows (on Doordarshan, NewsNation, Zee etc.) and is a widely read Quora writer. Each year he conducts more than 200 seminars in schools and colleges. He has mentored over 20,000 students and enabled them to achieve their goals.",
        data4: "Alok Bansal is the preferred choice as a speaker in public seminars and is the resource person for such events by The Times of India, Navbharat Times, Dainik Jagran and others.",
        
    },
    {
        title: "Dr. Naveen Gupta",
        data1: "Dr. Naveen Gupta, a renowned computer science educator, has been honored with numerous awards and accolades for his remarkable contributions to education. He received the National ICT award from the Ministry of Education in India, along with several national and international recognitions, acknowledging his exceptional leadership skills and innovative teaching methods.",
        data2: "He has made a positive impact on the education of hundreds of students, helping them to reach their goals and succeed in their chosen fields. He is a passionate advocate for innovative approaches to teaching and learning, and has played a key role in curriculum development committees for leading educational institutions such as the Central Board of Secondary Education (CBSE), National Institute of Open Schooling (NIOS), and the National Council of Educational Research and Training (NCERT).",
        data3: "Dr. Gupta has authored several books on computer science and Informatics practices for class 12 students, and training over 20,000 teachers globally. He has written research papers on the use of technology in the teaching and learning process and has played a crucial role in the organization of the National and International Tech-Fest 'GeekHertz,' a competition that provides a platform for students to showcase their skills and creations.",
        data4: "His outstanding leadership skills, unwavering passion for innovation, and dedication to the growth of students and teachers alike will undoubtedly continue to have a positive impact on the world of education for years to come.",
    }

]

const ProfileModal = (props) => {
    return <>
        <div className={classes.CloseButton}>
            <BiX/>
        </div>
        <div className={classes.ProfileModalCard}>
            <h1>{ProfileData[props?.dataId]?.title}</h1>
            <div className={classes.paragraphs}>
                <p>{ProfileData[props?.dataId]?.data1}</p><br/><br/>
                <p>{ProfileData[props?.dataId]?.data2}</p><br/><br/>
                <p>{ProfileData[props?.dataId]?.data3}</p><br/><br/>
                <p>{ProfileData[props?.dataId]?.data4}</p><br/><br/> 
            </div>
            

        </div>
    </>
}

export default ProfileModal;