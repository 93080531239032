import classes from "./BoardMemberCard.module.css"
import male from "../Assets/male.svg"
import female from "../Assets/female.svg"
import diyaSinghal from "../Assets/diyaSinghal.png"
import pranjal from "../Assets/Pranjal.png"
import amishaDhingra from "../Assets/AmishaDhingra.png"
import dhruv from "../Assets/dhruv.png"
import { motion } from "framer-motion"
import aarav from "../Assets/Aarav.png"
import sanidhya from "../Assets/sanidhya.png"
import gurmani from "../Assets/gurmani.png"

const BoardMemberCard = (props) => {
    console.log(props.gender)
    return <>
        <motion.div initial={{x: 200, opacity: 0}} whileInView={{x: 0, opacity: 1}} className={classes.Card}>
            <div className={classes.GenderLogo}>
                {props.gender === "gurmani" ? <img width="70%" className={classes.aarav} src={gurmani}/> : props.gender === "sanidhya" ? <img width="70%" className={classes.aarav} src={sanidhya}/> : props.gender ==="dhruv" ? <img width="70%" className={classes.aarav} src={dhruv}/> : props.gender === "aarav" ? <img width="70%" className={classes.aarav} src={aarav}/>  : props.gender === "pranjal" ? <img width="70%" className={classes.diyaSinghal} src={pranjal}/> : props.gender === "amishaDhingra" ? <img width="70%" className={classes.amishaDhingra} src={amishaDhingra}/> : props.gender === "diyaSinghal" ? <img width="70%" className={classes.diyaSinghal} src={diyaSinghal}/> : props.gender ? <img width="70%" src={female}/> : <img width="70%" src={male}/>}
                {/* {props.gender === "diyaSinghal" ? <img width="70%" className={classes.diyaSinghal} src={diyaSinghal}/> : props.gender ? <img width="70%" src={female}/> : <img width="70%" src={male}/>} */}
                {/* {props.gender ? <img width="70%" src={female}/> : <img width="70%" src={male}/>} */}
            </div>
            <div className={classes.Position}>{props.position}</div>
            <div className={classes.Club}>{props.club}</div>
            <div className={classes.Name}>{props.name}</div>
        </motion.div>
    </>
}

export default BoardMemberCard;