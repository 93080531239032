import classes from "./Team.module.css"
import AlokSirImage from "../Assets/AlokSIr.jpeg"
import DTU from "../Assets/DTU.png"
import Xavier from "../Assets/Xavier.png"
import Harvard from "../Assets/Harvard.png"
import ICICI from "../Assets/ICICI.png"
import LT from "../Assets/L&T.png"
import caltex from "../Assets/caltex.png"
import Exxon from "../Assets/Exxon.png"
import BoardMemberCard from "./BoardMemberCard"
// Naveen Sir 
import NaveenSirImg from "../Assets/NaveenSir.jpeg"
// modal 
import ProfileModal from "./profileModal"
import { useState } from "react"
import { delay, motion } from "framer-motion"


const Team = () => {
    
    const [profileModalData, setProfileModalData] = useState(0)
    const [profileModalState, setProfileModalState] = useState(false)

    const viewMore_A_clickHandler = () => {
         setProfileModalData(0)   
         setProfileModalState(true)
    }

    const viewMore_N_clickHandler = () => {
        setProfileModalData(1)
        setProfileModalState(true)
    }

    const modalClickHandler = () => {
        
        setProfileModalState(false)
    }

    return <>
        {profileModalState ? <div onClick={modalClickHandler} className={classes.profileModal}>
            <ProfileModal dataId = {profileModalData}/>
        </div> : <div></div>}
        {/* <Modal /> */}
        {/* <div className={classes.FounderSection}>
            <motion.div initial={{x: -100, opacity: 0}} animate={{x: 0, opacity: 1, transition: {}}} className={classes.photo}>
                <img src={AlokSirImage}/>
            </motion.div>
            <motion.div initial={{x: 400, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.3}}} className={classes.content}>
                <h1 className={classes.Title}>Alok Bansal,Founder</h1>
                <div className={classes.Logos}>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.5}}} width="70px" src={DTU}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.7}}} width="70px" src={Xavier}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.9}}} width="70px" src={Harvard}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.1}}} width="70px" src={LT}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.3}}} width="70px" src={caltex}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.5}}} width="70px" height="50px" src={Exxon}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.7}}} width="150px" height="50px" src={ICICI}/>
                </div>

                <div className={classes.FounderContent}>
                Alok Bansal is a Post Graduate in Management from Xavier Institute of Management, Bhubaneswar and an Engineering graduate from Delhi Technological University (Formerly known as Delhi College of Engineering). Besides, he has done a Course in Leadership from Harvard Business School. <br/><br/>
                He has worked in senior management positions at companies like ICICI Lombard, Larsen & Toubro, IBP Caltex, ExxonMobil etc.<br/><br/>
                An Expert Career Counsellor & Mentor, he appears in a lot of TV shows (on Doordarshan, NewsNation, Zee etc.) and is a widely read Quora writer. Each year he conducts more than 200 seminars in schools and colleges. He has mentored over 20,000 students and enabled them to achieve their goals.<br/><br/>
                Alok Bansal is the preferred choice as a speaker in public seminars and is the resource person for such events by The Times of India, Navbharat Times, Dainik Jagran and others.
                </div>
                <motion.div initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 2}}} onClick={viewMore_A_clickHandler} className={classes.FounderSection_ViewMore}>
                    View More
                </motion.div>
                
            </motion.div>
            
        </div> */}
        <div className={classes.FounderSection_NaveenSir}>

            <motion.div initial={{x: 200, opacity: 0}} whileInView={{x: 0, opacity: 1, transition: {delay: 0.2}}} className={classes.photo_NaveenSir}>
                <img src={NaveenSirImg} />
            </motion.div>
            <motion.div initial={{x: -200, scale: 0, y: -200}} whileInView={{x: 0, y: 0, scale: 1}} className={classes.NaveenSir_Content}>
                <h1 className={classes.Title}>Dr. Naveen Gupta,Founder</h1>
                <div className={classes.FounderContent_NaveenSir}>                
                    Dr. Naveen Gupta, a renowned computer science educator, has been honored with numerous awards and accolades for his remarkable contributions to education. He received the National ICT award from the Ministry of Education in India, along with several national and international recognitions, acknowledging his exceptional leadership skills and innovative teaching methods.<br/><br/>
                    He has made a positive impact on the education of hundreds of students, helping them to reach their goals and succeed in their chosen fields. He is a passionate advocate for innovative approaches to teaching and learning, and has played a key role in curriculum development committees for leading educational institutions such as the Central Board of Secondary Education (CBSE), National Institute of Open Schooling (NIOS), and the National Council of Educational Research and Training (NCERT).
                    Dr. Gupta has authored several books on computer science and Informatics practices for class 12 students, and training over 20,000 teachers globally. He has written research papers on the use of technology in the teaching and learning process and has played a crucial role in the organization of the National and International Tech-Fest "GeekHertz," a competition that provides a platform for students to showcase their skills and creations. <br/><br/>
                    His outstanding leadership skills, unwavering passion for innovation, and dedication to the growth of students and teachers alike will undoubtedly continue to have a positive impact on the world of education for years to come.
                </div>
                <div onClick={viewMore_N_clickHandler} className={classes.FounderSection_ViewMore_NaveenSir}>
                    View More
                </div>

            </motion.div>

        </div>
        {/* tablet */}
        {/* <div className={classes.FounderSection_tab}>
            <div className={classes.contentSectionOne_tab}>
                <motion.div initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1}} className={classes.photo_tab}>
                    <img  src={AlokSirImage}/>
                </motion.div>
                <div className={classes.titleAndLogo}>
                    <motion.h1 initial={{x: 200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.4}}} className={classes.Title_tab}>Alok Bansal,<br/>Founder</motion.h1>
                    <div className={classes.Logos_tab}>
                        <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.5}}} width="70px" src={DTU}/>
                        <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.7}}} width="70px" src={Xavier}/>
                        <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.9}}} width="70px" src={Harvard}/>
                        <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.1}}} width="70px" src={LT}/>
                        <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.3}}} width="70px" src={caltex}/>
                        <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.5}}} width="70px" height="50px" src={Exxon}/>
                        <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.7}}} width="150px" height="50px" src={ICICI}/>
                    </div>
                </div>
            </div>
            <div className={classes.content_tab}>
                
                
                <div className={classes.FounderContent_tab}>
                Alok Bansal is a Post Graduate in Management from Xavier Institute of Management, Bhubaneswar and an Engineering graduate from Delhi Technological University (Formerly known as Delhi College of Engineering). Besides, he has done a Course in Leadership from Harvard Business School. <br/><br/>
                He has worked in senior management positions at companies like ICICI Lombard, Larsen & Toubro, IBP Caltex, ExxonMobil etc.<br/><br/>
                An Expert Career Counsellor & Mentor, he appears in a lot of TV shows (on Doordarshan, NewsNation, Zee etc.) and is a widely read Quora writer. Each year he conducts more than 200 seminars in schools and colleges. He has mentored over 20,000 students and enabled them to achieve their goals.<br/><br/>
                Alok Bansal is the preferred choice as a speaker in public seminars and is the resource person for such events by The Times of India, Navbharat Times, Dainik Jagran and others.
                </div>
                
                
            </div>
            <div onClick={viewMore_A_clickHandler} className={classes.FounderSection_ViewMore_AlokSirTab}>
                    View More
                </div>
            
        </div> */}
        <div className={classes.FounderSection_NaveenSir_tab}>
            <div className={classes.NaveenSir_nameAndimg_tab}>
            <motion.div initial={{x: -200, opacity: 0}} whileInView={{x: 0, opacity: 1}} className={classes.photo_NaveenSir_tab}>
                <img src={NaveenSirImg} />
            </motion.div>
            <motion.h1 initial={{x: 200, opacity: 0}} whileInView={{x: 0, opacity: 1, transition: {delay: 0.4}}} className={classes.Title_tab}>Dr. Naveen Gupta,<br/>Founder</motion.h1>
            </div>
            
            
            <div className={classes.NaveenSir_Content_tab}>
                
                <div className={classes.FounderContent_NaveenSir_tab}>                
                    Dr. Naveen Gupta, a renowned computer science educator, has been honored with numerous awards and accolades for his remarkable contributions to education. He received the National ICT award from the Ministry of Education in India, along with several national and international recognitions, acknowledging his exceptional leadership skills and innovative teaching methods.<br/><br/>
                    He has made a positive impact on the education of hundreds of students, helping them to reach their goals and succeed in their chosen fields. He is a passionate advocate for innovative approaches to teaching and learning, and has played a key role in curriculum development committees for leading educational institutions such as the Central Board of Secondary Education (CBSE), National Institute of Open Schooling (NIOS), and the National Council of Educational Research and Training (NCERT).
                    Dr. Gupta has authored several books on computer science and Informatics practices for class 12 students, and training over 20,000 teachers globally. He has written research papers on the use of technology in the teaching and learning process and has played a crucial role in the organization of the National and International Tech-Fest "GeekHertz," a competition that provides a platform for students to showcase their skills and creations. <br/><br/>
                    His outstanding leadership skills, unwavering passion for innovation, and dedication to the growth of students and teachers alike will undoubtedly continue to have a positive impact on the world of education for years to come.
                </div>

            </div>
            <motion.div initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1}} onClick={viewMore_N_clickHandler} className={classes.FounderSection_ViewMore_NaveenSirTab}>
                    View More
                </motion.div>

        </div>

        {/* mobile  */}
        {/* <div className={classes.FounderSection_mobile}>
            <div className={classes.contentSectionOne_mobile}>
                <motion.div initial={{y: -200, opacity: 0}} animate={{y: 0, opacity: 1}} className={classes.photo_mobile}>
                    <img src={AlokSirImage}/>
                </motion.div>
                <motion.h1 initial={{x: 200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.4}}} className={classes.Title_mobile}>Alok Bansal,Founder</motion.h1>
                <div className={classes.Logos_mobile}>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.5}}} width="70px" src={DTU}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.7}}} width="70px" src={Xavier}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 0.9}}} width="70px" src={Harvard}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.1}}} width="70px" src={LT}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.3}}} width="70px" src={caltex}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.5}}} width="70px" height="50px" src={Exxon}/>
                    <motion.img initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {delay: 1.7}}} width="150px" height="50px" src={ICICI}/>
                </div>
                
            </div>
            <div className={classes.content_mobile}>
                

                <motion.div initial={{x: 200, opacity: 0}} whileInView={{x: 0, opacity: 1, transition: {duration: 0.4}}} className={classes.FounderContent_tab}>
                Alok Bansal is a Post Graduate in Management from Xavier Institute of Management, Bhubaneswar and an Engineering graduate from Delhi Technological University (Formerly known as Delhi College of Engineering). Besides, he has done a Course in Leadership from Harvard Business School. <br/><br/>
                He has worked in senior management positions at companies like ICICI Lombard, Larsen & Toubro, IBP Caltex, ExxonMobil etc.<br/><br/>
                An Expert Career Counsellor & Mentor, he appears in a lot of TV shows (on Doordarshan, NewsNation, Zee etc.) and is a widely read Quora writer. Each year he conducts more than 200 seminars in schools and colleges. He has mentored over 20,000 students and enabled them to achieve their goals.<br/><br/>
                Alok Bansal is the preferred choice as a speaker in public seminars and is the resource person for such events by The Times of India, Navbharat Times, Dainik Jagran and others.
                </motion.div>
                
            </div>
            <div onClick={viewMore_A_clickHandler} className={classes.FounderSection_ViewMore_NaveenSirTab}>
                    View More
                </div>
            
        </div> */}
        <div className={classes.FounderSection_NaveenSir_mobile}>
            <div className={classes.NaveenSir_nameAndimg_mobile}>
                <motion.div initial={{x: -200, opacity: 0}} whileInView={{x: 0, opacity: 1, transition: {duration: 0.5}}} className={classes.photo_NaveenSir_mobile}>
                    <img src={NaveenSirImg} />
                </motion.div>
                <motion.h1 initial={{x: 200, opacity: 0}} animate={{x: 0, opacity: 1, transition: {duration: 0.5, delay: 0.4}}} className={classes.Title_mobile}>Dr. Naveen Gupta,<br/>Founder</motion.h1>
            </div>
            
            <div className={classes.NaveenSir_Content_tab}>
                
                <div className={classes.FounderContent_NaveenSir_tab}>                
                    Dr. Naveen Gupta, a renowned computer science educator, has been honored with numerous awards and accolades for his remarkable contributions to education. He received the National ICT award from the Ministry of Education in India, along with several national and international recognitions, acknowledging his exceptional leadership skills and innovative teaching methods.<br/><br/>
                    He has made a positive impact on the education of hundreds of students, helping them to reach their goals and succeed in their chosen fields. He is a passionate advocate for innovative approaches to teaching and learning, and has played a key role in curriculum development committees for leading educational institutions such as the Central Board of Secondary Education (CBSE), National Institute of Open Schooling (NIOS), and the National Council of Educational Research and Training (NCERT).
                    Dr. Gupta has authored several books on computer science and Informatics practices for class 12 students, and training over 20,000 teachers globally. He has written research papers on the use of technology in the teaching and learning process and has played a crucial role in the organization of the National and International Tech-Fest "GeekHertz," a competition that provides a platform for students to showcase their skills and creations. <br/><br/>
                    His outstanding leadership skills, unwavering passion for innovation, and dedication to the growth of students and teachers alike will undoubtedly continue to have a positive impact on the world of education for years to come.
                </div>


            </div>
            <div onClick={viewMore_N_clickHandler} className={classes.FounderSection_ViewMore_NaveenSirTab}>
                    View More
                </div>

        </div>
        
        <div className={classes.BoardmemberSection}>
            <div className={classes.BoardTitle}>Board Members</div>
            <div className={classes.BoardMemebrContainer}>
                <BoardMemberCard gender={"diyaSinghal"} position="President" club="Design Club" name="Diya Singhal "/>
                <BoardMemberCard gender={"gurmani"} position="President" club="Computer Science Club" name="Gurmani Kaur"/>

                <BoardMemberCard gender={"amishaDhingra"} position="Vice President" club="Computer Science Club" name="Arnisha Dhingra"/>
                <BoardMemberCard gender={"pranjal"} position="Vice President" club="Computer Science Club" name="Pranjal Singh"/>
                <BoardMemberCard gender={false} position="Vice President" club="Computer Science Club" name="Ayush Sen "/>
                <BoardMemberCard gender={"aarav"} position="Vice President" club="Computer Science Club " name="Aarav Agrawal "/>
                <BoardMemberCard gender={"sanidhya"} position="Head" club="Computer Science Club" name="Sanidhya Barara"/>
                <BoardMemberCard gender={false} position="Executive Member" club="Computer Science Club" name="Hanush Mittal"/>
                <BoardMemberCard gender={false} position="Executive Member" club="Computer Science Club" name="Vansh Aggarwal"/>
                <BoardMemberCard gender={true} position="Executive Member" club="Computer Science Club" name="Navya Gupta"/>
                <BoardMemberCard gender={"dhruv"} position="Executive Member" club="Computer Science Club" name="Dhruv Rohilla"/>
            </div>
        </div>
    </>
}

export default Team;