import { Link } from "react-router-dom";
import classes from "./Navmenu.module.css"
import {motion} from "framer-motion"



const Navmenu = (props) => {

    const navItemClickHandler = () => {
        props.navMenuClickCollector()
    }

    return <motion.div initial={{y: 300, opacity: 0, scale: 0}} animate={ props.navMenuState ? {y: 0, opacity: 1, scale: 1} : {}} className={classes.Navmenu}>
        <Link to="/"><div onClick={navItemClickHandler} className={classes.Home}>Home</div></Link>
        <Link to="/events"><div onClick={navItemClickHandler} className={classes.Events}>Events</div></Link>
        <Link to="/team"><div onClick={navItemClickHandler} className={classes.Team}>Team</div></Link>
        <Link to="/about_us"><div onClick={navItemClickHandler} className={classes.AboutUs}>About Us</div></Link>
        <a href="https://forms.gle/DMmc3ctGq7EJyZNZ9" target="_blank"><div onClick={navItemClickHandler} className={classes.Member}>Join Us</div></a>
    </motion.div>
}

export default Navmenu;