import classes from "./Events.module.css"
import {motion} from "framer-motion"
import presentationImage from "../Assets/presentation.svg"
import awardsImage from "../Assets/awards.svg"
import { useState } from "react"



const Events = () => {

    // const [modalState, setModalState] = useState(false);

    // const eventsClickHandler = () => {
    //     setModalState(prevState => !prevState)
    // }

    return <>
        {/* {modalState ? <div onClick={eventsClickHandler} className={classes.Event_Modal}>
            <div className={classes.Events_data}>
                <h2>National-level competition for high school students</h2>
                <p>Unravelsciences.com is pleased to launch a national-level competition for high school students across the globe. The contest is open to students from all academic streams. </p>
                <h2>Details of the competition</h2>
                <p>Step 1 – The student has to fill in all the details in the google form, and pay the registration fee of Rs. 500 and attach the screenshot of the same.</p>
                <p>Step 2 – The student has to pick one of the following 17 sustainable development goals (SDGs) of the United Nations and draft an action plan to achieve that goal using their subject knowledge. For instance, a student having a keen interest in computer science can come out with an out-of-the-box idea to demonstrate how they can unleash the power of artificial intelligence to create an impact. </p>
                <h4>GOAL 1: No Poverty</h4>
                <h4>GOAL 2: Zero Hunger</h4>
                <h4>GOAL 3: Good Health and Well-being</h4>
                <h4>GOAL 4: Quality Education</h4>
                <h4>GOAL 5: Gender Equality</h4>
                <h4>GOAL 6: Clean Water and Sanitation</h4>
                <h4>GOAL 7: Affordable and Clean Energy</h4>
                <h4>GOAL 8: Decent Work and Economic Growth</h4>
                <h4>GOAL 9: Industry, Innovation and Infrastructure</h4>
                <h4>GOAL 10: Reduced Inequality</h4>
                <h4>GOAL 11: Sustainable Cities and Communities</h4>
                <h4>GOAL 12: Responsible Consumption and Production</h4>
                <h4>GOAL 13: Climate Action</h4>
                <h4>GOAL 14: Life Below Water</h4>
                <h4>GOAL 15: Life on Land</h4>
                <h4>GOAL 16: Peace and Justice Strong Institutions</h4>
                <h4>GOAL 17: Partnerships to achieve the Goal</h4>
                <p>Step 3 – The student has to upload a PPT of not more than 10 slides incorporating all the key points that cover the suggested action plan. The guidelines of the PPT have been mentioned below, and the student has to essentially adhere to the format to ensure uniformity. Presentation submitted in any other structure shall not be considered for evaluation.  </p>
                <h2>structure of Presentation</h2>
                <h4>Problem Statement</h4>
                <h4>Solution</h4>
                <h4>Execution Plan</h4>
                <h2>Result Structure</h2>
                <p>Out of all the contestants, 50 students would be shortlisted based on their ability to approach the problem effectively and innovatively. These 50 students would be invited to present their solutions virtually to a panel of evaluators. </p>
                <p>Finally, the top 20 candidates, including the top 3 winners, will be announced. These 20 contestants would be facilitated and awarded a prize.</p>
                <h1></h1>
            </div>
        </div> : ""} */}
        <div className={classes.Events}>
            
                <div className={classes.Event_Section}>
                    <motion.h1 initial={{x: -200, opacity: 0}} animate={{x: 0, opacity: 1}} >Events</motion.h1>
                    <div className={classes.EventList}>
                    <a target="_blank" href="https://drive.google.com/file/d/1hLSvO0aU5Wc6ebQ1v9UtP6aLNsCSkzWG/view?usp=sharing">
                        <motion.div initial={{x: 200, opacity: 0}} animate={{x: 0, opacity: 1}}  className={classes.Event_One}>
                            <img width="200px" src={awardsImage}/>
                            <h2 className={classes.TitleColor}>Sustainability Innovative Award Excellence Award 2.0</h2>
                        </motion.div>
                        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe4WaHrWyha_4OGXiJ1IV1c23y-x98tYPnAqOL5hBu5NUMeXA/viewform"><div className={classes.register}>
                            Register
                        </div></a>
                    </a>
                    <a target="_blank" href="https://drive.google.com/file/d/1NMHehYh1ohm8nFZV9_AKC_NaiPjD8ffS/view?usp=sharing">
                        <motion.div initial={{x: 200, opacity: 0}} animate={{x: 0, opacity: 1}}  className={classes.Event_One}>
                            <img width="200px" src={presentationImage}/>
                            <h2 className={classes.TitleColor}>National-level competition for high school students</h2>
                        </motion.div>
                        <a target="_blank" href="https://forms.gle/dVkTGLsVzwd4r3F67"><div className={classes.register}>
                            Register
                        </div></a>
                    </a>
                    </div>
                    
                </div>

                
            
           
        </div>
    </>
}

export default Events;