import { useState } from "react";
import classes from "./Navbar.module.css"
import { FiAlignJustify } from "react-icons/fi";
import { FaCompressAlt } from "react-icons/fa";
import LogoImg from "../Assets/logo.png"
import { Link, BrowserRouter } from "react-router-dom";


const Navbar = (props) => {

    const [hamburgerState, setHamburgerState] = useState(true)

    const hamburgerClickHandler = () => {
        setHamburgerState(prevState => !prevState)
        // setHamburgerState(props.hamburgerState)
        props.hamburgerDataCollector(hamburgerState)
    }

    const logoClickHanlderForNavMenu = () => {
        props.navMenuClickCollector()
    }
    

    return (

        
        <>
        
        <div className={classes.Navbar}>
            <div onClick={logoClickHanlderForNavMenu} className={classes.Logo}>
                <Link to="/"><img width="80px" src={LogoImg}/></Link>
            </div>
            <div className={classes.NavItems}>
                <Link to="/">Home</Link>
                <Link to="/events"><div className={classes.NavItem_Clubs}>Events</div></Link>
                <Link to="/team"><div className={classes.NavItem_Events}>Team</div></Link>
                <Link to="/about_us"><div className={classes.NavItem_AboutUs}>About Us</div></Link>
                <a href="https://forms.gle/DMmc3ctGq7EJyZNZ9" target="_blank"><div className={classes.NavItem_Member}>Become A Member</div></a>
            </div>
            <div onClick={hamburgerClickHandler} className={classes.Hamburger_Icon}>
                {hamburgerState ? <FiAlignJustify color="black" size="50px" /> : <FaCompressAlt/>}
            </div>
        </div>
        
        <div className={classes.HamburgerMenu}>
            <div className={classes.Hamburger_Club}>Events</div>
            <div className={classes.Hamburger_Events}>Team</div>
            <div className={classes.Hamburger_AboutUs}>About Us</div>
            <div className={classes.Member}>Become A Member</div>
        </div>
       
        </>
    )
}

export default Navbar;